import * as Tone from "tone";

type Screens = {
  form?: HTMLElement;
  processing?: HTMLElement;
  playback?: HTMLElement;
  error?: HTMLElement;
};

const screens: Screens = {};

let currentScreen: HTMLElement;

const goto = (screen: string) => {
  // @ts-ignore
  if(currentScreen) currentScreen.style = "display: none";
  currentScreen = screens[screen];
  // @ts-ignore
  currentScreen.style = "display: flex";
}

const handleError = (err) => {
  goto('error')
  let message: string;

  switch (err) {
    case "fetch_failed":
      message = "Failed to load track.";
      break;
    case "invalid_url":
    case "no_audio_available":
      message = "couldn`t get audio for that track.";
      break;
    case "audio_too_long":
      message = "that track is too long. Max 10 minutes please.";
      break;
  }

  document.querySelector("#error-message").innerHTML = message;

  
};

const playAudio = (url: string) => {
  goto('playback')

  const player = new Tone.Player(url);
  player.autostart = true;

  const filter = new Tone.Filter(500, "lowpass", -24);
  const reverb = new Tone.Reverb(3).toDestination();

  player.connect(filter);
  filter.connect(reverb);
};

const download = async (url: string) => {
  goto('processing')

  try {
    const response = await fetch(`${process.env.API_URL}/decoded?url=${url}`);
    const data = await response.json();

    if (data.status == "error") {
      handleError(data.message);
      return;
    }

    if (data.url) {
      playAudio(data.url);
    }
  } catch (err) {
    handleError("fetch_failed");
  }
};

document.addEventListener("DOMContentLoaded", () => {
  const form = document.querySelector("form");
  const field = document.querySelector("#yt-url") as HTMLInputElement;

  screens.form = document.querySelector("#form");
  screens.processing = document.querySelector("#processing");
  screens.playback = document.querySelector("#playback");
  screens.error = document.querySelector("#error");

  goto('form')

  form.addEventListener("submit", (evt) => {
    evt.preventDefault();
    download(field.value);
  });
});
